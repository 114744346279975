import { render, staticRenderFns } from "./rewardDetails.vue?vue&type=template&id=d6808be8&scoped=true&"
import script from "./rewardDetails.vue?vue&type=script&lang=js&"
export * from "./rewardDetails.vue?vue&type=script&lang=js&"
import style0 from "./rewardDetails.vue?vue&type=style&index=0&id=d6808be8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6808be8",
  null
  
)

export default component.exports