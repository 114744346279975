<template>
	<div class="systemSetDiv">
		<div class="systemFrom">
			<div class="fs-16 fw-bold mb-20">
				<span class="w-10 h-10 bgc-0076f6 mr-10 dis-in-block"></span>
				<span>基础配置</span>
			</div>
			<div class="mb-20 flex flex-ac">
				<el-button type="primary" @click="initRedisData" class="mr-20">刷新缓存</el-button>
			</div>
			<div class="fs-16 fw-bold mb-20">
				<span class="w-10 h-10 bgc-0076f6 mr-10 dis-in-block"></span>
				<span>基础配置</span>
			</div>
			<el-form :model="ruleForm" ref="ruleForm" :inline="true" label-width="250px">
				<el-form-item label="初级礼包兑换码" prop="noviceCdk">
					<el-input v-model="ruleForm.noviceCdk"></el-input>
				</el-form-item>
				<el-form-item label="抽盒机器人" prop="boxRobOff">
					<el-switch v-model="ruleForm.boxRobOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="一番赏机器人" prop="goodRobOff">
					<el-switch v-model="ruleForm.goodRobOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="排队一番赏机器人" prop="sysGoodRcgOff">
					<el-switch v-model="ruleForm.sysGoodRcgOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="高级礼包触发D级达标" prop="ideOff">
					<el-switch v-model="ruleForm.ideOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="新用户累计消费" prop="newUserConsume">
					<el-input v-model="ruleForm.newUserConsume"></el-input>
				</el-form-item>
				<el-form-item label="三抽送" prop="triGiftCount">
					<el-input type="number" v-model="ruleForm.triGiftCount"></el-input>
				</el-form-item>
				<el-form-item label="十抽送" prop="tdGiftCount">
					<el-input type="number" v-model="ruleForm.tdGiftCount"></el-input>
				</el-form-item>
				<el-form-item label="0元抽邀请老用户开关" prop="helpOldUserOff">
					<el-switch v-model="ruleForm.helpOldUserOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="新用户能量值" prop="newEnergy">
					<el-input type="number" v-model="ruleForm.newEnergy"></el-input>
				</el-form-item>
				<el-form-item label="老用户能量值" prop="oldEnergy">
					<el-input type="number" v-model="ruleForm.oldEnergy"></el-input>
				</el-form-item>
				<el-form-item label="幸运转盘新人邀请人数" prop="newUserCount">
					<el-input type="number" v-model="ruleForm.newUserCount"></el-input>
				</el-form-item>
				<el-form-item label="幸运转盘老用户助力天数" prop="luckyTurnDay">
					<el-input type="number" v-model="ruleForm.luckyTurnDay"></el-input>
				</el-form-item>
				<el-form-item label="幸运转盘老人邀请人数" prop="oldUserCount">
					<el-input type="number" v-model="ruleForm.oldUserCount"></el-input>
				</el-form-item>
				<el-form-item label="排队倒计时" prop="waitSeconds">
					<el-input type="number" v-model="ruleForm.waitSeconds"></el-input>
				</el-form-item>
				<el-form-item label="一番赏保底" prop="goodMust">
					<el-input v-model="ruleForm.goodMust" placeholder="格式:次数-概率"></el-input>
				</el-form-item>
				<el-form-item label="订单自动发货周期" prop="autoSendDay">
					<el-input type="number" v-model="ruleForm.autoSendDay"></el-input>
				</el-form-item>
				<el-form-item label="一番赏机器人购买时间(分钟)" prop="goodRobotMinutes">
					<el-input type="number" v-model="ruleForm.goodRobotMinutes"></el-input>
				</el-form-item>
				<el-form-item label="一番赏全收比例(大于等于)" prop="autoFptPct">
					<el-input type="number" v-model="ruleForm.autoFptPct"></el-input>
				</el-form-item>
				<el-form-item label="一番赏全收限制次数" prop="goodFptNum">
					<el-input type="number" v-model="ruleForm.goodFptNum"></el-input>
				</el-form-item>
				<el-form-item label="退款操作" prop="name">
					<el-select v-model="ruleForm.wxRefundType">
						<el-option label="退款删订单" :value="0"></el-option>
						<el-option label="只退款" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业微信ID" prop="name">
					<el-input v-model="ruleForm.wxId" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="公众号图片" prop="name">
					<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
						iconName="el-icon-upload" iconSize="26" v-model="ruleForm.appletImg"></upload-file>
				</el-form-item>
				<el-form-item label="春节通知开关" prop="showBanOff">
					<el-switch v-model="ruleForm.showBanOff" :active-value="true" :inactive-value="false"></el-switch>
				</el-form-item>
			</el-form>
			<div class="save_btn">
				<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
			</div>
			<div class="fs-16 fw-bold mb-20">
				<span class="w-10 h-10 bgc-0076f6 mr-10 dis-in-block"></span>
				<span>微信支付配置</span>
			</div>
			<el-form :model="rulePayForm" ref="rulePayForm" :inline="true" label-width="200px">
				<el-form-item label="小程序AppID" prop="name">
					<el-input v-model="rulePayForm.appId" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="小程序AppSecret" prop="name">
					<el-input v-model="rulePayForm.appSecret" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="微信支付证书" prop="name">
					<el-input v-model="rulePayForm.certPath" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="微信支付商户号" prop="name">
					<el-input v-model="rulePayForm.mchId" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="微信支付商户号密钥" prop="name">
					<el-input v-model="rulePayForm.apiKey" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="签名加密方式" prop="name">
					<el-input v-model="rulePayForm.signType" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="小程序token" prop="name">
					<el-input v-model="rulePayForm.token" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="是否打开" prop="delivery">
					<el-switch v-model="rulePayForm.isDele" style="width: 300px;"></el-switch>
				</el-form-item>
<!-- 				<el-form-item>
					<el-button type="primary">保存</el-button>
				</el-form-item> -->
			</el-form>
			<div class="save_btn">
				<el-button type="primary" @click="submitForm('rulePayForm')">保存</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				ruleForm: {
					noviceCdk:'',//初级礼包兑换码
					wxId: '', //微信id
					drawNum: '', //领取次数
					appletImg: '', //微信图片
					boxRobOff: 0, //抽盒机器人开关
					goodRobOff: 0, //一番赏机器人开关
					sysGoodRcgOff: 0, //排队一番赏机器人开关
					ideOff: 0, //是否开启bug开关
					triGiftCount: 0, //三抽送的数量
					tdGiftCount: 0, //十抽送的数量
					goodMust:'',//一番赏保底
					newUserConsume:'',
					newEnergy: '', //新用户能量值
					oldEnergy: '', //老用户能量值
					waitSeconds:0,//排队倒计时
					luckyTurnDay:7,
					helpOldUserOff:0,//邀请老用户开关
					autoSendDay:0,//订单发货周期
					goodRobotMinutes:0,//一番赏机器人购买时间
					autoFptPct:0,//一番赏全收比例
					dayConsumeNote: '',
					dayConsumeType: '',
					goodFptNum:0,
					showBanOff:false
				},
				configData: [],
				WxIfo: [],
				rulePayForm: {
					apiKey: '', //小程序密钥
					appId: '', //小程序的应用ID
					appSecret: '', //服务号的应用密钥
					certPath: '', //微信支付证书
					isDele: '', //是否删除
					mchId: '', //商户号
					signType: '', //签名加密方式
					token: '', //小程序的配置token
				}
			}
		},
		mounted() {
			this.findWxInFo()
			this.findSystemConfig()
		},
		methods: {
			//基础数据 微信支付配置 保存
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let apiUrl = ''
						if (formName == 'rulePayForm') {
							if (this.WxIfo.length > 0) {
								apiUrl = '/wxInfo/editWxInfo'
							} else {
								apiUrl = '/wxInfo/addWxInfo'
							}
						} else if (formName == 'ruleForm') {
							if (this.configData.length > 0) {
								apiUrl = '/systemConfig/editSystemConfig'
							} else {
								apiUrl = '/systemConfig/addSystemConfig'
							}
						}
						this.$http.post(apiUrl, this[formName]).then((result) => {
							this.$message({
								message: '保存成功',
								type: 'success'
							})
							if (formName == 'rulePayForm') {
								this.findWxInFo()
							} else if (formName == 'ruleForm') {
								this.findSystemConfig()
							}
						}).catch((err) => {})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			//查询基础配置
			findSystemConfig() {
				this.$http.get('/systemConfig/findSystemConfig').then((result) => {
					let configData = result.data.data
					this.configData = configData
					if (configData.length > 0) {
						this.ruleForm = configData[0]
					}
				}).catch((err) => {})
			},
			//查询支付配置
			findWxInFo() {
				this.$http.get('/wxInfo/findWxInfo').then((result) => {
					let WxIfo = result.data.data
					this.WxIfo = WxIfo
					if (WxIfo.length > 0) {
						this.rulePayForm = WxIfo[0]
					}
				}).catch((err) => {})
			},
			//刷新缓存
			initRedisData() {
				this.$confirm('您确认刷新缓存？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/robot/initRedisData').then(() => {
						this.$message({
							message: '刷新成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//同步数据
			synchronization() {
				this.$http.post('/seriesAll/saveSeriesAll').then((result) => {
					this.$message({
						message: '同步成功',
						type: 'success'
					})
				}).catch((err) => {})
			},
		}
	}
</script>
<style lang="less" scoped>
	.systemSetDiv {
		height: 100%;
		background: #fff;
		overflow-y: scroll;
		.save_btn {
			text-align: center;
			margin: 0px auto 20px auto;
		}
		.systemFrom {
			width: 1300px;
			padding: 10px 30px;
		}
	}
</style>