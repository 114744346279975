<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<div class="flex flex-ac" v-if="activeName == 1 || activeName == 2">
						<div class="mr-30 flex flex-ac">
							<span class="fs-16 flex1 mr-20">名称：</span>
							<el-input class="w-250" v-model="retrieveForm.exchangeName" clearable></el-input>
						</div>
						<div class="mr-30 flex flex-ac">
							<span class="fs-16 flex1 mr-20">类型：</span>
							<el-select class="w-250" v-model="retrieveForm.propType" clearable placeholder="请选择">
								<el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="mr-10">
							<el-button type="primary" @click="transferbtn">传递多个</el-button>
						</div>
						<div label="">
							<el-button type="primary" @click="submitSearch">查询</el-button>
						</div>
					</div>
					<!-- <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue" v-else>
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input> -->
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<div class="tableDiv pall-30">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="积分道具" name="1">
					<TableHeightFull>
						<EleTable ref="tableRef" slot="table" :tableData="tableData" key="table1" :columns="columns" height="100%"
							:border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
							@selection-change="handleSelectionChange">
							<template v-slot:img="scope">
								<img :src="scope.row.img" alt="" class="smallImg">
							</template>
						</EleTable>
						<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
					</TableHeightFull>
				</el-tab-pane>
				<el-tab-pane label="卡片道具" name="2">
					<TableHeightFull>
						<EleTable ref="tableRef" slot="table" :tableData="tableData" key="table2" :columns="columns" height="100%"
							:border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
							<template v-slot:img="scope">
								<img :src="scope.row.img" alt="" class="smallImg">
							</template>
						</EleTable>
						<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
					</TableHeightFull>
				</el-tab-pane>
				<!-- <el-tab-pane label="每日福利" name="3">
          <TableHeightFull>
            <EleTable ref="tableRef" slot="table" :tableData="tableData" key="table3" :columns="wealColumns" height="100%"
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
              <template v-slot:img="scope">
                <img :src="scope.row.propImg" alt="" class="tableImg">
              </template>
            </EleTable>
          </TableHeightFull>
        </el-tab-pane>
        <el-tab-pane label="新人活动" name="4">
          <TableHeightFull>
            <EleTable ref="tableRef" slot="table" :tableData="tableData"  key="table4" :columns="newWealColumns" height="100%"
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
            </EleTable>
          </TableHeightFull>
        </el-tab-pane> -->
			</el-tabs>
		</div>
		<!-- 积分 卡片 道具 -->
		<activity-prop-details ref="activity-prop-details" @refresh="getTableData"></activity-prop-details>
		<!-- 每日福利 新人活动 道具 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="15vh" width="30%" @close="resetForm">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="道具名称" prop="propName" v-if="'propName' in ruleForm"
					:rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
					<el-input v-model="ruleForm.propName"></el-input>
				</el-form-item>
				<el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm"
					:rules="{required: true, message: '请至少选择一个道具卡类型', trigger: 'change'}">
					<el-select v-model="ruleForm.propType" placeholder="请选择">
						<el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value"
							v-if="item.value != 1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="道具图片" prop="propImg" v-if="'propImg' in ruleForm && activeName == 3"
					:rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
					<UploadFile upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
						iconName="el-icon-upload" iconSize="26" v-model="ruleForm.propImg"></UploadFile>
				</el-form-item>
				<el-form-item label="道具数量" prop="num" v-if="'num' in ruleForm"
					:rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.num"></el-input>
				</el-form-item>
				<el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm"
					:rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.validDay"></el-input>
				</el-form-item>
				<el-form-item label="优惠券面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
					:rules="{required: true, message: '优惠券面额不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.money"></el-input>
				</el-form-item>
				<el-form-item label="优惠券类型" prop="couponsType" v-if="'couponsType' in ruleForm && ruleForm.propType == 4"
					:rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
					<el-select v-model="ruleForm.couponsType" placeholder="请选择">
						<el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
					:rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.doorSill"></el-input>
				</el-form-item>
				<el-form-item label="排序" prop="sort" v-if="'sort' in ruleForm">
					<el-input type="number" v-model="ruleForm.sort"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 传递数据 -->
		<el-dialog title="传递数据" :visible.sync="transferVisible">
			<div class="dialog-content">
				<el-form :model="platUrl" ref="platUrl" label-width="100px">
					<el-form-item label="选择平台" prop="platformUrl" v-if="'platformUrl' in platUrl"
						:rules="{required: true, message: '请选择平台', trigger: 'change'}">
						<el-select v-model="platUrl.platformUrl" placeholder="请选择">
							<el-option v-for="item,index in platform" :key="item.value" :label="item.key"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="transferVisible = false">取 消</el-button>
				<el-button type="primary" @click="certain">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'activityProp',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
			"activity-prop-details": (resolve) => require(["@/views/modules/activity/activityPropDetails"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'exchangeTypes': state => state.dictList ? state.dictList.exchange_type : [], //兑换类型 1积分 2卡片
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				retrieveForm: {
					exchangeName: '',
					propType: '',
				},
				activeName: '1',
				tabLoading: false,
				butLoading: false,
				columns: [{
						type: 'selection',
						align: 'center',
						width: "30",
						fixed: 'left',
					}, {
						label: '排序',
						prop: 'sort',
						align: 'center',
						width: '60',
					},
					{
						columnType: 'custom',
						label: '封面图',
						prop: 'img',
						align: 'left',
						width: '80',
					},
					{
						label: '名称',
						prop: 'exchangeName',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'fun',
						label: '类型',
						prop: 'propType',
						align: 'left',
						width: '80',
						formatData: (e) => {
							let ele = this.propTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					{
						label: '库存',
						prop: 'inventory',
						align: 'left',
						width: '80'
					},
					{
						label: '已兑换数量',
						prop: 'exchangeNum',
						align: 'left',
						width: '100'
					},
					{
						label: '兑换次数',
						prop: 'exNum',
						align: 'left',
						width: '100'
					},
					{
						label: '兑换价格',
						prop: 'price',
						align: 'left',
						width: '100'
					},
					{
						columnType: 'fun',
						label: '兑换类型',
						prop: 'exchangeType',
						align: 'left',
						width: '100',
						formatData: (e) => {
							let ele = this.exchangeTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					{
						label: '面额',
						prop: 'money',
						align: 'left',
						width: '80'
					},
					{
						label: '门槛',
						prop: 'doorSill',
						align: 'left',
						width: '80'
					},
					{
						columnType: 'fun',
						label: '优惠劵类型',
						prop: 'couponsType',
						align: 'left',
						width: '100',
						formatData: (e) => {
							let ele = this.couponsTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					{
						label: '创建时间',
						prop: 'createDate',
						align: 'left',
						width: '180',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '150',
						buttons: [{
								type: 'text',
								text: '传递',
								fn: this.transferList
							},
							{
								type: 'text',
								text: '编辑',
								fn: this.editList
							},
							{
								type: 'text',
								text: '删除',
								fn: this.deleteList
							}
						],
					},
				],
				transferVisible: false,
				transferData: '',
				platUrl: {
					platformUrl: ''
				},
				selectData: '',
				ids: '', //选择的系列id
				platform: [{
					value: 'https://manghe.hzhxyqchy.com/manghe',
					key: '乐芒抽盒机'
				}, {
					value: 'https://sauce.hzhxyqchy.com/ouqi',
					key: '欧气酱潮玩'
				}, {
					value: 'https://bobo.hzhxyqchy.com/bob',
					key: '啵啵抽盒机'
				}, {
					value: 'https://gull.hzkuqu.com/gull',
					key: '一只鸥抽盒机'
				}, {
					value: 'https://mart.hzhxyqchy.com/mart',
					key: '趣丸抽盒机'
				}, {
					value: 'https://donut.hzhxyqchy.com/donut/',
					key: '甜甜圈抽盒机'
				}],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					propName: '', //道具名称
					propType: '', //道具类型
					propImg: '', //道具图片
					num: '', //道具数量
					validDay: '', //有效天数
					money: '', //优惠券面额
					doorSill: '', //使用门槛
					couponsType: '', //优惠券类型
					sort: '',
				},
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//导出
			exportExcel() {},
			//选择传递数据
			handleSelectionChange(val) {
				this.selectData = val
			},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				if (this.activeName == 1 || this.activeName == 2) {
					this.$http.get('/exchange/findExchange', {
						params: {
							currentPage: this.pageInfo.pageNum,
							pageSize: this.pageInfo.pageSize,
							exchangeName: this.retrieveForm.exchangeName,
							exchangeType: this.activeName,
							propType: this.retrieveForm.propType,
						}
					}).then(({
						data: result
					}) => {
						this.tabLoading = false
						this.tableData = result.data.list
						this.pageInfo.total = result.data.total
					}).catch(err => {
						this.tabLoading = false
					})
				} else if (this.activeName == 3 || this.activeName == 4) {
					this.$http.get('/dayActyProp/findDayActyPropList', {
						params: {
							actyType: this.activeName == 3 ? 0 : 1,
						}
					}).then(({
						data: result
					}) => {
						this.tabLoading = false
						this.tableData = result.data
					}).catch(err => {
						this.tabLoading = false
					})
				}
			},
			//切换 Tabs 标签
			handleClick() {
				this.getTableData()
			},
			//新增列表
			addList() {
				if (this.activeName == 1 || this.activeName == 2) {
					let sort = this.pageInfo.total + 1
					this.$refs['activity-prop-details'].init('', sort, this.activeName)
				} else if (this.activeName == 3 || this.activeName == 4) {
					this.dialogTitle = "新增"
					let tableData = this.tableData,
						sort = ''
					if (tableData.length > 0) {
						if (tableData[0].sort) {
							sort = tableData[0].sort + 1
						}
					}
					if (this.activeName == 3) {
						this.ruleForm = {
							actyType: 0,
							propName: '',
							propImg: '',
							propType: '',
							num: '',
							validDay: '',
							money: '',
							doorSill: '',
							couponsType: '',
							sort: sort,
						}
					} else {
						this.ruleForm = {
							actyType: 1,
							propName: '',
							propType: '',
							num: '',
							validDay: '',
							money: '',
							doorSill: '',
							couponsType: '',
							sort: sort,
						}
					}
					this.dialogFormVisible = true
				}
			},
			// 传递单个
			transferList(row) {
				this.transferData = row
				this.transferVisible = true
			},
			//传递多个
			transferbtn() {
				let selectData = this.selectData
				if (selectData.length > 0) {
					this.transferVisible = true
					let idList = selectData.map(ele => {
						return ele.id
					})
					let ids = idList.join(',')
					this.ids = ids
				} else {
					this.$message({
						showClose: true,
						message: '请勾选传递的商品',
						type: 'error'
					});
				}
			},
			//单个传递
			certain() {
				let transferParm = {
					url: this.platUrl.platformUrl,
					ids: this.ids ? this.ids : this.transferData.id
				}
				// let transfer = JSON.stringify(transferParm)
				this.$http.post('/exchange/sendGiveOtheSys', transferParm).then(res => {
					this.$message({
						message: '传递成功',
						type: 'success'
					})
					this.platUrl.platformUrl = ''
					this.transferVisible = false
					this.butLoading = false
					this.getTableData()
					// this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			//编辑
			editList(row) {
				if (this.activeName == 1 || this.activeName == 2) {
					this.$refs['activity-prop-details'].init(row.id, '', this.activeName)
				} else if (this.activeName == 3 || this.activeName == 4) {
					this.dialogTitle = "编辑"
					this.ruleForm = JSON.parse(JSON.stringify(row))
					this.dialogFormVisible = true
				}
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ""
						if (this.dialogTitle == '新增') {
							apiUrl = "/dayActyProp/saveDayActyProp"
						} else if (this.dialogTitle == '编辑') {
							apiUrl = "/dayActyProp/editDayActyProp"
						}
						let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
						if (ruleForm.propType != 4) {
							ruleForm.money = ''
							ruleForm.doorSill = ''
							ruleForm.couponsType = ''
						}
						this.$http.post(apiUrl, ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.getTableData()
							this.butLoading = false
							this.dialogFormVisible = false
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					if (this.activeName == 1 || this.activeName == 2) {
						this.$http.post('/exchange/removeExchange', {
							id: row.id
						}).then(res => {
							this.getTableData()
							this.$message({
								message: '删除成功',
								type: 'success'
							})
						})
					} else if (this.activeName == 3 || this.activeName == 4) {
						this.$http.post('/dayActyProp/removeDayActyProp', {
							id: row.id
						}).then(res => {
							this.getTableData()
							this.$message({
								message: '删除成功',
								type: 'success'
							})
						})
					}
				})
			},
			//跳转详情
			headerRowClick(row) {
				console.log(row)
			},
			//重置清除校验
			resetForm() {
				Object.assign(
					this.$data.ruleForm,
					this.$options.data().ruleForm
				)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>

<style lang="less" scoped>
</style>