<template>
	<el-drawer title="限时商品" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">限时商品</div>
				<i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
			</div>
			<div class="pall-30 flex flex-ac flex-jb">
				<div style="color: red; font-weight: bold;font-size: 22px;">*注意：上新品，请勿在老商品上操作修改。</div>
				<el-button type="primary" @click="addDomain">新增</el-button>
			</div>
			<div class="flex-1 overflow-scroll-y pall-30">
				<el-form :inline="true" ref="formDataRef" :model="formData" class="demo-form-inline">
					<div class="add_list flex flex-ac mb-20 pv-20" v-for="domains,index in formData.goodExchDetails" :key="index">
						<div class="width-450">
							<el-form-item label="商品图片" label-position="right" :prop="'goodExchDetails.' + index + '.duckImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.duckImg"></upload-file>
							</el-form-item>
							<el-form-item label="商品详情图" label-position="right" :prop="'goodExchDetails.' + index + '.detailImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.detailImg"></upload-file>
							</el-form-item>
							<el-form-item label="商品名称" :prop="'goodExchDetails.' + index + '.duckName'"
								:rules="{required: true,message: '请输入商品名称',trigger: 'change'}">
								<el-input v-model="domains.duckName" type="text" placeholder="商品名称"></el-input>
							</el-form-item>
							<el-form-item label="库存" :prop="'goodExchDetails.' + index + '.invt'"
								:rules="{required: true,message: '请输入库存',trigger: 'change'}">
								<el-input v-model="domains.invt" type="text" placeholder="库存"></el-input>
							</el-form-item>
							<el-form-item label="兑换等级" :prop="'goodExchDetails.' + index + '.exchLevelType'"
								v-if="domains.isEditLevel" :rules="{required: true,message: '请选择兑换等级',trigger: 'change'}">
								<el-select v-model="domains.exchLevelType" multiple placeholder="选择兑换等级(支持多选)"
									:popper-append-to-body="false" @remove-tag="removeTag">
									<el-option v-for="item,ind in levelTypes" :key="item.value" :label="item.key" :value="item.value">
										{{ item.key }}
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="兑换价格" :prop="'goodExchDetails.' + index + '.exchNum'"
								:rules="{required: true,message: '请输入兑换价格',trigger: 'change'}">
								<el-input v-model="domains.exchNum" type="text" placeholder="兑换价格" clearable
									style="width: 100px;"></el-input>
							</el-form-item>
							<el-form-item label="预售时间" :prop="'goodExchDetails.' + index + '.presellDate'">
								<el-date-picker v-model="domains.presellDate" valueFormat="yyyy-MM-dd" type="date" placeholder="预售时间">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="说明" :prop="'goodExchDetails.' + index + '.note'">
								<el-input v-model="domains.note" type="text" placeholder="说明"></el-input>
							</el-form-item>
						</div>
						<div class="flex flex-ac">
							<el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20">删除
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'limitReward',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'levelTypes': state => state.dictList ? state.dictList.level_type : [], //道具类型
			})
		},
		data() {
			return {
				isDrawer: false,
				tabLoading: false,
				butLoading: false,
				isAddList: true,
				goodId: '',
				formData: {
					seriesId: '',
					goodExchDetails: [{
						invt: '',
						duckImg: '',
						duckName: '',
						exchLevelType: [],
						exchNum: '',
						presellDate: '',
						note: '',
					}]
				}
			}
		},
		methods: {
			init(id) {
				this.isDrawer = true
				this.formData.seriesId = id
				if (id) {
					this.goodId = id
					this.getDetails(id)
				}
				// this.getTableData()
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/goodExchDetail/findGoodExchDetailList?seriesId=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					goodData.forEach(item => {
						item.isEditLevel = true
						item.exchLevelType = item.exchLevelType.split(',').map(Number)
						this.levelTypes.forEach((elm, idx) => {
							elm.check = item.exchLevelType.includes(elm.value);
						})
					})
					this.formData.goodExchDetails = goodData
				})
			},
			addDomain() {
				this.isAddList = true
				// this.levelTypes.forEach((elm, idx) => {
				// 	elm.check = false
				// })
				if (this.formData.goodExchDetails.length > 0) {
					this.formData.goodExchDetails.push({
						invt: '',
						duckImg: '',
						duckName: '',
						exchLevelType: [],
						exchNum: '',
						presellDate: '',
						note: '',
						isEditLevel: false,
					})
				} else {
					this.formData.goodExchDetails = [{
						invt: '',
						duckImg: '',
						duckName: '',
						exchLevelType: [],
						exchNum: '',
						presellDate: '',
						note: '',
						isEditLevel: false,
					}]
				}

				console.log(this.formData.goodExchDetails, 'this.formData.goodExchDetails')
			},
			removeDomain(index) {
				this.formData.goodExchDetails.splice(index, 1)
			},
			// isChecked(item, index) {
			// 	debugger
			// 	const value = item.value; // 获取复选框的值
			// 	const currentDetail = this.formData.goodExchDetails[index]; // 假设操作第一个 item，如果有多个，请根据需要调整
			// 	if (item.check) {
			// 		// 复选框选中，添加到 exchLevelType
			// 		if (!currentDetail.exchLevelType.includes(value)) {
			// 			currentDetail.exchLevelType.push(value)
			// 		}
			// 	} else {
			// 		// 复选框未选中，从 exchLevelType 中移除
			// 		currentDetail.exchLevelType = currentDetail.exchLevelType.filter(type => type !== value);
			// 	}
			// 	console.log(this.formData.goodExchDetails[index],'this.formData.goodExchDetails[index]')
			// },
			// 多选模式下移除tag时触发
			removeTag(value) {
				this.levelTypes.forEach((elm, idx) => {
					if (elm.value == value) {
						elm.check = false
					}
				})
			},
			editOkSubmit() {
				this.dialogVisible = false
				this.formData.goodExchDetails.forEach(item => {
					item.exchLevelType = item.exchLevelType.join(',');
				})
				let goodData = JSON.stringify(this.formData.goodExchDetails)
				let apiUrl = ''
				apiUrl = `/goodExchDetail/editGoodExchDetail?seriesId=${this.goodId}`
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: '操作成功',
						type: 'success'
					})
					this.butLoading = false
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			//提交
			submitForm() {
				this.$refs['formDataRef'].validate((valid) => {
					if (valid) {
						if (!valid) return
						this.editOkSubmit()
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(this.$data.formData, this.$options.data.call(this).formData)
				this.$nextTick(() => {
					this.$refs.formDataRef.clearValidate()
				})
			},
			handleClose() {
				this.resetForm()
				this.isDrawer = false
			},
		}
	}
</script>

<style lang="less" scoped>
	.el-checkbox {
		width: 100%;
	}

	.add_list {
		padding-top: 20px;
		border: 2px solid #eeeeee;
	}
</style>